<template>
	<div class="editpscontent">
		<div class="edittitle">修改密码</div>
		<div class="editbox">
			<el-form
				:model="ruleForm"
				:rules="rules"
				ref="ruleForm"
				label-width="100px"
				class="demo-ruleForm"
			>
				<el-form-item prop="name">
					<div class="inputtitle">
						<span>*</span>
						<div>新密码</div>
					</div>
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item prop="name">
					<div class="inputtitle">
						<span>*</span>
						<div>确认新密码</div>
					</div>
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item prop="name">
					<div class="inputtitle">
						<span>*</span>
						<div>手机验证码</div>
					</div>
					<div class="yanzmbox">
						<el-input v-model="ruleForm.name"></el-input>
						<div class="yzmbtn">
							获取验证码
						</div>
					</div>
					
				</el-form-item>
			<!-- 	<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item> -->
			</el-form>
			<div class="confirmbtn">
				确认修改
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			ruleForm: {
				name: '',
				region: '',
				date1: '',
				date2: '',
				delivery: false,
				type: [],
				resource: '',
				desc: ''
			},
			rules: {
				name: [
					{ required: true, message: '请输入活动名称', trigger: 'blur' },
					{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				region: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
				date1: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
				date2: [{ type: 'date', required: true, message: '请选择时间', trigger: 'change' }],
				type: [
					{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
				],
				resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
				desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
			}
		};
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	}
};
</script>

<style scoped lang="scss">
	.confirmbtn {
		margin: 3.125em 0 1.25em;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 11.25em;
		height: 3em;
		background: #3266F7;
		border-radius: 1.5em;
		font-size: 1em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
	}
	.yzmbtn {
		width: 7.5625em;
		height: 2.5em;
		background: rgba(50, 102, 247, 0.1);
		border-radius: 0.5em;
		border: 1px solid #3266F7;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1em;
		font-family: PingFang-SC-Regular, PingFang-SC;
		font-weight: 400;
		color: #3266F7;
		margin: 0 0 0 1.25em;
	}
	.yanzmbox {
		display: flex;
		align-items: center;
	}
.inputtitle {
	display: flex;
	align-items: center;
	margin: 0 0 1.1875em 0;
	span {
		color: #ff0000;
		margin: 0 0.625em 0 0;
	}
	div {
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
}
::v-deep {
	.el-form-item__content {
		margin-left: 0 !important;
		line-height: 1.25em;
	}
}
.editbox {
	padding: 1.25em;
	width: 55em;
	
	background: #ffffff;
	box-shadow: 0px 0px 0.625em 0px rgba(50, 102, 247, 0.14);
	border-radius: 0.125em;
	margin: 1.75em 0 0;
}
.edittitle {
	font-size: 1.25em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #0f0f0f;
}
.editpscontent {
	background-color: #ffffff;
	padding: 1.6875em 4.375em;
	margin: 0.9375em 0 0;
}
@media screen and (max-width: 1920px) {
	.editpscontent {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.editpscontent {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.editpscontent {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.editpscontent {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.editpscontent {
		font-size: 12px;
	}
}
</style>
